import { format, addDays, toDate } from 'date-fns'
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import "./Games.scss";
import { useCallback, useEffect, useState } from 'react';
import { apiHeaders, apiPath } from '../lib/api';
import axios from 'axios';
import { useInterval } from 'usehooks-ts'
import { Game } from '../lib/types';
import Media from '../components/Media';

type RouteParams = {
  dateId: string
}

const Games = () => {
  const { dateId } = useParams<RouteParams>();
  let currentDate = new Date();
  if(dateId) {
    currentDate = toDate(dateId + " 00:00:00");
  }

  const searchCurrentDate = format(currentDate, "yyyy-MM-dd");
  const showCurrentDate = format(currentDate, 'EEEE MMMM do yyyy'); 
  const nextDate = format(addDays(currentDate, 1), "yyyy-MM-dd");
  const showNextDate = format(addDays(currentDate, 1), "M/dd/yyyy")
  const prevDate = format(addDays(currentDate, -1), "yyyy-MM-dd");
  const showPrevDate = format(addDays(currentDate, -1), "M/dd/yyyy")

  const [games, setGames] = useState<Game[]>([]);
  const [loaded, setLoaded] = useState(false);
  
  document.title = 'MLB Underground - ' + showCurrentDate;

  const sortGames = (games: Game[]) => {
    const sorted: Game[] = [];
    games.filter((game) => 
      (game.category === 'live')).map((game) => { sorted.push(game); return true; });
    games.filter((game) => 
      (game.category === 'scheduled')).map((game) => { sorted.push(game); return true; });
    games.filter((game) => 
      (game.category === 'game-two')).map((game) => { sorted.push(game); return true; });
    games.filter((game) => 
      (game.category === 'final')).map((game) => { sorted.push(game); return true; });
    return sorted;
  };


  
  const fetchGames = useCallback(() => {
    if(searchCurrentDate) {
      axios.get(apiPath() + '/games/' + searchCurrentDate, apiHeaders())
      .then(response => {
        setGames(sortGames(response.data));
        setLoaded(true);
      }).catch(() => {
        console.log('error');
      });
    }
  }, [searchCurrentDate]); 

  const gameBoxes = games.map((game) => (
      <div key={game.gameId} className={`game ${game.category}`}>
        <div className="game-inner">
          <h3>{game.title}</h3>
          <h4>{game.venue}</h4>
          <h5>{game.status}</h5> 
          <div className="media-wrapper">
            <Media game={game} feedType="Video" />
            <Media game={game} feedType="Audio" />
          </div>
        </div>      
      </div>
  ));

  useInterval(() => {
    fetchGames();
  }, 25000);
 
  useEffect(() => {
    fetchGames();
  }, [dateId, fetchGames]);
  
  return (
    <div className="page games-page">
      <h2>
        <span>{showCurrentDate}</span>
        <Link to={`/games/${nextDate}`}>{showNextDate} &raquo;</Link>
        <Link to={`/games/${prevDate}`}>&laquo; {showPrevDate}</Link>
      </h2>
      {loaded && games.length === 0 && <div className="games">There are no games scheduled on this date.</div>}
      {loaded && games.length > 0 && <div className="games">
        {gameBoxes}
      </div>}
    </div>
  );
}
 
export default Games;
