import { EventInning, Event } from "../lib/types";
import "./Events.scss";

type EventProps = {
  events: EventInning[]
}

const Roster = (props: EventProps) => {
  const { events } = props;

  const buildInnings = (innings: EventInning[]) => innings.map((inning, index) => (
    <div key={index} className="inning">
      <h3>{inning.title}</h3>
      {buildEvents(inning.events)}    
    </div>
  ));
    
  const buildEvents = (events: Event[]) => events.map((event, index) => (
    <div key={index} className="sidebar-item sidebar-item-player">
      {event.title}
      <div className="image" style={{
          backgroundImage: 'url(//content.mlb.com/images/headshots/current/60x60/' + event.player_id + '@2x.png)'
      }}></div>
    </div>
  ));

  return(
    <div className="events">
      {buildInnings(events)}
    </div>
  )
}

export default Roster
