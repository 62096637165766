import { restoreLocalUser } from "../context/CurrentUserContext";
import { User } from "./types";

export const apiPath = () => {
    let url = 'https://media.mlbunderground.com';
    if(window.location.href.indexOf('localhost') !== -1) {
      url = 'http://localhost:4000';
    }
    return url;
}

export const apiHeaders = () => {
    const currentUser:User = restoreLocalUser()
    return {
        headers: { 
            Authorization: `Bearer ${currentUser.accessToken}`,
            'X-Timezone':  Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    }
}
