import { useEffect, useRef } from "react";
import videojs from 'video.js';
import dvr from 'videojs-dvr'
import "video.js/dist/video-js.css";

type VideoPlayerProps = {
  url: string
}

const VideoPlayer = (props: VideoPlayerProps) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<any | null>(null);

  const { url } = props;

  useEffect(() => {
    if (!playerRef.current) {

      videojs.registerPlugin('dvr', dvr);

      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");

      if(videoRef.current) {
        videoRef.current.appendChild(videoElement);
      }

      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        experimentalSvgIcons: true,
        poster: '//i.imgur.com/lKhS5pj.jpeg',
        sources: [
          {
            src: url,
            type: "application/x-mpegURL",
          },
        ],
        plugins: {
          dvr: {}
        }
      };

      const player = (playerRef.current = videojs(videoElement, videoJsOptions, () => {}));

      player.on('ready', () => { 
        player.volume(parseFloat(localStorage.getItem('player-volume') || '0.5'));
      });

      player.on('volumechange', () => {
        localStorage.setItem('player-volume', player.volume() + '' || '0.5');
      });
    }
  }, [videoRef, url]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoPlayer;