import React, { useState } from "react"
import { BrowserRouter } from "react-router-dom"
import Routes from "./Routes"
import CurrentUserContext, { restoreLocalUser } from "./context/CurrentUserContext";
import Nav from "./components/Nav";
import "./App.scss";
import { User } from "./lib/types";

const App:React.FC = () => {
  const [user, setUser] = useState<User | null>(restoreLocalUser());
  return (
    <BrowserRouter>
      <CurrentUserContext.Provider value={{user, setUser}}>
        <Nav />
        <Routes />
      </CurrentUserContext.Provider>
    </BrowserRouter>
  )
}

export default App