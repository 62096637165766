import React from "react"
import { useCurrentUser } from "../context/CurrentUserContext";
import { Link } from "react-router-dom";
import "./Nav.scss";

const Nav = () => {
  const currentUser = useCurrentUser();
  const user = currentUser?.user;
  return (
    <nav>
      <Link to="/">
        MLB Underground
      </Link>
      {user && user.accessToken && (
        <ul>
          <li>Hi, {user?.username}&nbsp;&nbsp;| </li>
          <li><Link to="/logout">Log Out</Link></li>
        </ul>
      )}
    </nav>
  )
}

export default Nav
