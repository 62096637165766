import { WrapDetails } from "../lib/types";

type WrapProps = {
  wrap: WrapDetails;
}

const Wrap = (props: WrapProps) => {
  const { wrap } = props;

  return(
    <div className="tab tab-preview">
      <h3>{wrap.title}</h3>
      {wrap.winner_id === '' && wrap.loser_id === '' && 
        <div className="sidebar-item">
          The only losers today are the fans.
        </div>
      }
      {wrap.winner_id !== '' &&
        <div className="sidebar-item sidebar-item-player">
            <div className="image" style={{
                backgroundImage: 'url(//content.mlb.com/images/headshots/current/60x60/' + wrap.winner_id + '@2x.png)'
            }}></div>
            {wrap.winner}
        </div>
      }
      {wrap.loser_id !== '' &&
        <div className="sidebar-item sidebar-item-player">
            <div className="image" style={{
                backgroundImage: 'url(//content.mlb.com/images/headshots/current/60x60/' + wrap.loser_id + '@2x.png)'
            }}></div>
            {wrap.loser}
        </div>
      }
    </div>
  )
}

export default Wrap
