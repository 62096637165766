import { useEffect } from "react";
import { removeLocalUser, useCurrentUser } from "../context/CurrentUserContext";
import { useNavigate } from "react-router";

const Logout = () => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate()

  useEffect(() => {
    currentUser?.setUser({
      username: '',
      id: 0,
      accessToken: '',
    });
    removeLocalUser();
    navigate('/login');
  }, [currentUser, navigate]);
  return (<div></div>);
};

export default Logout;