import { useParams } from "react-router";

import "./Player.scss";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { apiHeaders, apiPath } from "../lib/api";
import { useInterval } from "usehooks-ts";
import { GameData, Stream } from "../lib/types";
import Linescore from "../components/Linescore";
import Preview from "../components/Preview";
import Roster from "../components/Roster";
import Matchup from "../components/Matchup";
import Wrap from "../components/Wrap";
import Events from "../components/Events";
import VideoPlayer from "../components/VideoPlayer";

type RouteParams = {
  gameId: string;
  feedType: string;
  mediaId: string;
}

type GameDataResponse = {
  data: GameData
}

type StreamResponse = {
  data: Stream
}

const Player = () => {
  const { mediaId, gameId } = useParams<RouteParams>();

  const [openTab, setOpenTab] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [gameTitle, setGameTitle] = useState('');
  const [stream, setStream] = useState<Stream>({
    url: '',
    startTime: '',
    errors: [],
  });

  const [streamErrors, setStreamErrors] = useState<String>('')
  const [attempts, setAttempts] = useState(0);

  const [gameData, setGameData] = useState<GameData>({
    title: '',
    gameId: '',
    status: '',
    linescore: {
      gameState: '', currentInning: 1, inningState: 'Top', labels: [], top: [], bottom: []
    },
    preview: {
      title: '',
      description: '',
      home: '',
      home_id: '',
      away: '',
      away_id: '',
    },
    wrap: {
      title: '',
      winner: '',
      winner_id: '',
      loser: '',
      loser_id: '',
    },
    teams: {
      away: {
        id: '', 
        name: '', 
        abbreviation: '', 
        batting: {key: '', players: []},
        pitching: {key: '', players: []},
        bench: {key: '', players: []},
        bullpen: {key: '', players: []}
      },
      home: {
        id: '', 
        name: '', 
        abbreviation: '', 
        batting: {key: '', players: []},
        pitching: {key: '', players: []},
        bench: {key: '', players: []},
        bullpen: {key: '', players: []}
      }
    },
    matchup: {
      pitcher: {id: '', title: '', stats: ''},
      batter: {id: '', title: '', stats: ''},
      onDeck: {id: '', title: '', stats: ''},
      inHole: {id: '', title: '', stats: ''},
    },
    events: []
  });

  const fetchGame = useCallback(() => {
    axios.get(apiPath() + '/game/' + gameId, apiHeaders())
    .then((response:GameDataResponse) => {
      setGameData(response.data);
      if (response.data.title !== gameTitle) {
        setGameTitle(response.data.title);
        document.title = 'MLB Underground - ' + response.data.title;
      }
      setLoaded(true);
    }).catch(() => {
      console.log('error');
    });
  }, [gameId, gameTitle]); 

  const fetchStream = useCallback(() => {
    axios.get(apiPath() + '/media/' + mediaId  + '/stream.json', apiHeaders())
    .then((response:StreamResponse) => {
      setStream(response.data);
      if(response.data.errors.length) {
        setStreamErrors(response.data.errors[0].message);
      }
    }).catch(() => {
      console.log('error');
    });
  }, [mediaId]); 

  useInterval(() => {
    if(stream.url === '' && mediaId) {
      setAttempts(attempts+1);
      fetchStream();
    }
  }, 5000);
  
  useInterval(() => {
    fetchGame();
  }, 5000);
  
  useEffect(() => {
    fetchStream();
    fetchGame();
  }, [fetchStream, fetchGame]);

  useEffect(() => {
    // console.log('gameData changed');
    // console.log(gameData);
    //  console.log(openTab);

    if(['S', 'P'].includes(gameData.status) && openTab === '') {
      setOpenTab('preview');
    } else if(['L', 'F', 'PW'].includes(gameData.status) && openTab === '') {
      setOpenTab('game');
    }
  }, [gameData, openTab]);
  

  return (
    <div className="page player-page">
      <div className="main-content">
        <div className="player-wrapper">
          {stream.url === '' && 
            <div className="player-wait">
              <img src="//i.imgur.com/lKhS5pj.jpeg" alt="MLB Underground" />
              <div className="wait-message">
              {streamErrors !== '' && <span>{streamErrors}!</span>}
              {streamErrors === '' && attempts < 5 && <span>Searching for stream...</span>}
              {streamErrors === '' && attempts >= 5 && <span>The stream may not be active yet<br /> but I'm still searching...</span>}
              </div>
            </div>
          }
          {stream.url !== '' && 
            <VideoPlayer url={stream.url} />
          }
        </div>   
        <Linescore gameData={gameData} />
      </div>
      
      <div className="sidebar">
        {loaded && 
          <div className="sidebar-inner">
            <div className="tabs">
              {['S', 'P'].includes(gameData.status) && 
                <div
                    tabIndex={0}
                    role="tab"
                    key="preview"
                    className={openTab === 'preview' ? 'active' : ''}
                    onClick={() => setOpenTab('preview')}
                >
                  Preview
                </div>
              }
              {(['F', 'PW', 'L'].includes(gameData.status)) && 
                <div
                    tabIndex={0}
                    role="tab"
                    key="game"
                    className={openTab === 'game' ? 'active' : ''}
                    onClick={() => setOpenTab('game')}
                >
                  Game
                </div>
              }
              <div
                  tabIndex={0}
                  role="tab"
                  key="home"
                  className={openTab === 'home' ? 'active' : ''}
                  onClick={() => setOpenTab('home')}
              >
                {gameData.teams.home.name}
              </div>
              <div
                  tabIndex={0}
                  role="tab"
                  key="away"
                  className={openTab === 'away' ? 'active' : ''}
                  onClick={() => setOpenTab('away')}
              >
                {gameData.teams.away.name}
              </div>
            </div>
            <div className="tab-contents">
              {openTab === 'preview' && <Preview preview={gameData.preview} /> }
             
              {openTab === 'game' && 
                <div>
                  
                  {['PW', 'L'].includes(gameData.status) && <Matchup matchup={gameData.matchup} /> }

                  {['F'].includes(gameData.status) && <Wrap wrap={gameData.wrap} /> }

                  <Events events={gameData.events} />

                </div>
              }
              {openTab === 'away' && <Roster team={gameData.teams.away} /> }
              {openTab === 'home' && <Roster team={gameData.teams.home} /> }
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default Player;
