import {Routes as Router, Route, Navigate, Outlet} from 'react-router-dom'
import Games from './pages/Games'
import Login from './pages/Login'
import { useCurrentUser } from './context/CurrentUserContext'
import Logout from './pages/Logout'
import Player from './pages/Player'

const PrivateRoutes = () => {
    const currentUser = useCurrentUser();
    if (!currentUser?.user?.accessToken) return <Navigate to="/login" />;
    return <Outlet />;
}

const Routes = () => {
  return (
    <Router>
      <Route path='/login' element={<Login />}/>
      <Route element={<PrivateRoutes />}>
        <Route path='/' element={<Games />} />
        <Route path='/games/:dateId' element={<Games />} />
        <Route path='/player/:gameId/:feedType/:mediaId' element={<Player />} />
      </Route>
      <Route path='/logout' element={<Logout />}/>
    </Router>
  )
}

export default Routes