import { createContext, useContext } from "react";
import { User } from "../lib/types";

export interface CurrentUserContextType {
  user: User | null;
  setUser: (newValue: User) => void;
}

const CurrentUserContext = createContext<CurrentUserContextType | null>(null);

export default CurrentUserContext;

export const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};

export const restoreLocalUser = () => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser') || '');
    return user;
  } catch (e) { 
      return {
        username: '',
        id: 0, 
        accessToken: '',
      }
  }
}

export const removeLocalUser = () => {
  localStorage.removeItem('currentUser');
}
