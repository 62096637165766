import { Link } from "react-router-dom";
import { Game } from "../lib/types";

type MediaProps = {
  game: Game;
  feedType: string;
}

const Media = (props: MediaProps) => {
  const { game, feedType } = props;
  const feeds = game.feeds
    .filter((feed) => (feed.feedType === feedType))
    .sort((a, b) => a.location < b.location ? -1 : a.location > b.location ? 1 : 0)  
    .map((feed) => (
    <div className="media" key={feed.mediaId} >
        <Link to={`/player/${game.gameId}/video/${feed.mediaId}`}>
            <div className="logo" style={{backgroundImage: 'url(//www.mlbstatic.com/team-logos/' + feed.teamId + '.svg)'}}>
            </div>{feed.callLetters}
        </Link>
    </div>
  ));

  return(
    <div className="medias">{feeds}</div>
  )
}

export default Media
