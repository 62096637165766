import { useState } from "react";
import { useCurrentUser } from "../context/CurrentUserContext";
import { useNavigate } from "react-router";
import axios from 'axios';
import { apiPath } from "../lib/api";
import './Login.scss';

const Login = () => {
  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const [formError, setFormError] = useState(false);

  const currentUser = useCurrentUser();
  const navigate = useNavigate()

  const handleSubmitEvent = (e: any) => {
    e.preventDefault();
    axios.post(apiPath() + '/auth', input)
      .then(response => {
        localStorage.setItem('currentUser', JSON.stringify(response.data));
        currentUser?.setUser(response.data);
        navigate('/');
      }).catch(() => {
        setFormError(true);
      });

    return;
  };

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="login-wrapper form">
      <div className="disclaimer">MLB Underground is not affiliated with <br />Major League Baseball, MLB or MLB.com</div>
      <form className="form" onSubmit={handleSubmitEvent}>
        <h2>Login to your MLB Underground Account</h2>
        <div className="form-inner">
          {formError && <div className="error">Invalid username or password</div>}
          <div className="form-group">
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Username"
              aria-describedby="username"
              aria-invalid="false"
              onChange={handleInput}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              aria-describedby="user-password"
              aria-invalid="false"
              onChange={handleInput}
            />
          </div>
          <div className="form-group">
              <button className="btn btn-primary btn-block">
                Login
              </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;